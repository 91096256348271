import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, take, finalize } from 'rxjs/operators';
import { User } from 'src/app/shared/interfaces/AuthUser';
import { Business } from 'src/app/shared/interfaces/Business';
import { CollectionsService, UserService } from '../../../shared/services';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './profil.component.scss']
})
export class ProfilComponent implements OnInit {
  user?: any;
  userData?: User;
  allClosedBusiness: Business[] = [];
  allOngoingBusiness: Business[] = [];
  allClosedBusinessAmount = 0;
  allOngoingBusinessAmount = 0;
  business: any[];

  translateStatus = {
    PENDING: 'Affaire en attente',
    ONGOING: 'Affaire en cours',
    CLOSE: 'Affaire conclue',
    NOT_CLOSE: 'Affaire non conclue',
  }

  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
    private cdr: ChangeDetectorRef
  ) {
    this.getUser();
  }

  ngOnInit() {
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.getAllBusiness();
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  getAllBusiness() {
    this.collectionsService.getCollectionByKeyValue('Business', 'depositorUid', this.user.uid)
    .pipe(
      map(
        (business: QuerySnapshot<any>) => business.docs.map(d => {
          return Object.assign(d.data(), {uid: d.id});
        }),
        take(1)
      ),
    ).subscribe(business => {
      this.business = business.slice(0, 3);

      this.allClosedBusiness = business.filter(value => value.status === 'CLOSE');
      this.allClosedBusiness.forEach(b => {
        this.allClosedBusinessAmount += b.amount_1;
      })

      this.allOngoingBusiness = business.filter(value => value.status === 'ONGOING');
      this.allOngoingBusiness.forEach(b => {
        this.allOngoingBusinessAmount += b.amount_1;
      })
    }, err => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    })
  }
}
