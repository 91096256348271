import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private storage: LocalStorageService
) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.user$.pipe(
      take(1),
      map((user) => user.data?.admin),
      tap((isAdmin) => {
          if (!isAdmin) {
            this.storage.remove('admin');
            this.router.navigate(['/client']);
          }
      }),
  );
  }
}