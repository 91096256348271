import { CollectionsService } from './collections.service';
import { EmailTemplatesService } from './email-templates.service';
import { UserService } from './user.service';

export * from './user.service';
export * from './collections.service';
export * from './email-templates.service';

export const services = [
    UserService,
    CollectionsService,
    EmailTemplatesService
];
