import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../../services';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './login-page.component.scss']
})
export class LoginPageComponent {
  form : FormGroup;
  loading = false;
  showPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(100)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }

  submit () {
    if (this.form.invalid) {
      this._snackBar.open('Merci de vérifier le formulaire', null, {
        duration: 3000,
      });
      return;
    }

    this.loading = true;

    this.authService
      .signInWithEmailAndPassword(this.form.value)
      .then(() => {
        this.loading = false;
        this.router.navigate(['/client']);
      })
      .catch(() => {
        this._snackBar.open('Erreur de connexion', null, {
          duration: 3000,
        });
        this.loading = false;
      });
  }

}
