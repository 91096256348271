import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../../services';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {
  form : FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(100)])],
    });
  }

  submit () {
    if (this.form.invalid) {
      this._snackBar.open('Merci de vérifier le formulaire', null, {
        duration: 3000,
      });
      return;
    }

    this.loading = true;

    this.authService.forgotPassword(this.form.get('email').value)
    .then(() => {
      this.form.reset();
      this.loading = false;
      this._snackBar.open('Si votre addresse est enregistrée vous receverez un mail dans un instant. N\'hésitez pas à vérifier dans vos mails indésirables.', null, {
        duration: 5000,
      });
      this.router.navigate(['/auth/login']);
    })
    .catch((err) => {
      console.log(err);
      this.loading = false;
      this._snackBar.open('Si votre addresse est enregistrée vous receverez un mail dans un instant. N\'hésitez pas à vérifier dans vos mails indésirables.', null, {
        duration: 3000,
      });
    });
  }

}
