import { ChangeDetectorRef, Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CollectionsService,
  EmailTemplatesService,
  UserService,
} from "../../../shared/services";

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { map, take } from "rxjs/operators";
import { Business } from "src/app/shared/interfaces/Business";
import { User } from "src/app/shared/interfaces/AuthUser";

@Component({
  selector: "app-create-business",
  templateUrl: "./create-business.component.html",
  styleUrls: [
    "../../../shared/assets/styles/styles.scss",
    "./create-business.component.scss",
  ],
})
export class CreateBusinessComponent {
  form: FormGroup;
  loading = false;
  user?: any;
  userData?: User;
  businessUid?: string;
  currentBusinessFromUid?: any;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private emailTemplatesService: EmailTemplatesService
  ) {
    this.form = this.formBuilder.group({
      firstName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      lastName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("[0-9]{10}"),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      origin: [""],
      comment: [""],
      politics: ["", Validators.compose([Validators.required])],
    });

    if (this.route.snapshot.params.uid) {
      this.businessUid = this.route.snapshot.params.uid;
      this.collectionsService
        .getCollectionByDoc("Business", this.businessUid)
        .pipe(
          map((doc) => doc.data()),
          take(1)
        )
        .subscribe((data: Business) => {
          Object.assign(data, { uid: this.businessUid });
          this.currentBusinessFromUid = data;
          this.form.patchValue(data);
          this.cdr.markForCheck();
        });
    }

    this.getUser();

    this.form.get("origin").valueChanges.subscribe((data) => {
      if (data === "other") {
        this.form.addControl(
          "origin_other",
          new FormControl("", Validators.required)
        );
      } else {
        this.form.removeControl("origin_other");
      }
    });
  }

  getUser() {
    this.userService.user$.subscribe(
      (user) => {
        this.user = user;
        this.userData = user.data;
      },
      () => {
        this._snackBar.open("Une erreur est survenue", null, {
          duration: 3000,
        });
      }
    );
  }

  submit() {
    if (this.form.invalid) {
      this._snackBar.open("Merci de vérifier le formulaire", null, {
        duration: 3000,
      });
      return;
    }

    if (this.form.get("origin").value === "other") {
      this.form.value.origin = "Autre : " + this.form.get("origin_other").value;
    }

    if (this.businessUid) {
      Object.assign(this.form.value, {
        amount_1: this.form.get("origin").value === "Particulier" ? 1500 : 500,
        amount_2: this.form.get("origin").value === "Particulier" ? 500 : 1500,
        updatedAt: moment().format("DD/MM/YYYY"),
      });

      this.collectionsService
        .putDocumentCollectionWithUid(
          "Business",
          this.businessUid,
          this.form.value
        )
        .then(() => {
          this.form.reset();
          this.form.markAsPristine();

          if (
            this.form.get("origin").value === "Particulier" &&
            this.currentBusinessFromUid.origin === "Professionnel"
          ) {
            const emailBody = {
              to: this.currentBusinessFromUid.createdBy.email,
              subject: "Changement de votre affaire effectué",
              content: this.emailTemplatesService.partToProForColab(),
            };

            this.collectionsService.postDocumentCollection("Emails", emailBody);
          } else if (
            this.form.get("origin").value === "Professionnel" &&
            this.currentBusinessFromUid.origin === "Particulier"
          ) {
            const emailBody = {
              to: this.currentBusinessFromUid.createdBy.email,
              subject: "Changement de votre affaire effectué",
              content: this.emailTemplatesService.proToPartForColab(),
            };

            this.collectionsService.postDocumentCollection("Emails", emailBody);
          }

          this.router.navigate(["/client/business"]);
        })
        .catch(() => {
          this._snackBar.open("Une erreur est survenue", null, {
            duration: 3000,
          });
        });
    } else {
      Object.assign(this.form.value, {
        status: "PENDING",
        depositorUid: this.user.uid,
        createdBy: this.userData,
        companyId: this.userData.companyId,
        createdAt: moment().format("DD/MM/YYYY"),
        amount_1: this.form.get("origin").value === "Particulier" ? 1500 : 500,
        amount_2: this.form.get("origin").value === "Particulier" ? 500 : 1500,
      });

      this.collectionsService
        .postDocumentCollection("Business", this.form.value)
        .then(() => {
          const data = {
            colab: this.userData,
            prospect: this.form.value,
            company: this.userData.company,
          };

          const emailsBody = [
            {
              to: this.userData.email,
              subject: "Votre affaire est déposée !",
              content: this.emailTemplatesService.newBusinessForColab(data),
            },
            {
              to: this.form.get("email").value,
              subject: "Et si on parlait de votre projet ?",
              content: this.emailTemplatesService.newBusinessForProspect(data),
            },
            {
              to: this.userData.company.accountableEmail,
              subject:
                "Une affaire a été déposée par un de vos collaborateurs !",
              content: this.emailTemplatesService.newBusinessForRespo(data),
            },
            {
              to: "contact@saintclair-patrimoine.fr",
              subject: `Nouvelle affaire déposée par ${data.colab.firstName} ${data.colab.lastName} de ${data.company.name} !`,
              content: this.emailTemplatesService.newBusinessForAdmin(data),
            },
          ];

          emailsBody.forEach((emailBody) => {
            this.collectionsService.postDocumentCollection("Emails", emailBody);
          });
          this.form.reset();
          this.form.markAsPristine();
          this.router.navigate(["/client/business"]);
        })
        .catch(() => {
          this._snackBar.open("Une erreur est survenue", null, {
            duration: 3000,
          });
        });
    }
  }

  canDeactivate(): boolean {
    return !this.form.dirty;
  }

  canDeactivateMessage(): string {
    return "Les modifications n'ont pas été enregistrées, êtes-vous sûr ?";
  }
}
