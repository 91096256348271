import { CookieComponent } from "./cookie/cookie.component";
import { LoggedMenuComponent } from "./logged-menu/logged-menu.component";
import { NotLoggedMenuComponent } from "./not-logged-menu/not-logged-menu.component";

export * from "./not-logged-menu/not-logged-menu.component";
export * from "./cookie/cookie.component";
export * from "./logged-menu/logged-menu.component";

export const components = [
    NotLoggedMenuComponent,
    CookieComponent,
    LoggedMenuComponent,
]