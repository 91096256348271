import { ChangeDetectorRef, Component } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { finalize, map, take } from 'rxjs/operators';
import { AdminUserService } from 'src/app/admin/services';
import { AuthService } from 'src/app/auth/services';
import { User } from 'src/app/shared/interfaces/AuthUser';
import { Business } from 'src/app/shared/interfaces/Business';
import { CollectionsService, UserService } from '../../../shared/services';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './parameters.component.scss']
})
export class ParametersComponent {
  user?: any;
  userData?: User;
  showForm = false;
  form : FormGroup;
  loading = false;
  business: Business[] = [];
  allBusiness: Business[] = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private collectionsService: CollectionsService,
    private adminUserService: AdminUserService,
    private storage: LocalStorageService
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(100)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(100)])],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]{10}')])],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(100)])],
      job: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(100)])],
    });
    this.getUser();
  }

  getUser() {
    this.userService.user$
    .pipe(
      finalize(() => {
        this.cdr.markForCheck();
      })
    )
    .subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.getBusiness(user.uid);
      if (this.userData) {
        this.form.patchValue(this.userData);
      }
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  getBusiness(userUid: string) {
    this.collectionsService.getCollectionByKeyValue('Business', 'depositorUid', userUid)
    .pipe(
      map(
        (business: QuerySnapshot<any>) => business.docs.map(d => {
          return Object.assign(d.data(), {uid: d.id});
        }),
        take(1)
      )
    ).subscribe(business => {
      this.allBusiness = business;
      this.business = business.filter(value => value.status === 'ONGOING');
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    })
  }

  logout() {
    this.userService.resetUser();
  }

  submit() {
    this.loading = true;

    const body = Object.assign(this.form.value, { displayName: this.form.get('firstName').value + ' ' + this.form.get('lastName').value});

    this.userService.putUserEmail(this.user, this.form.get('email').value).then(() => {
      this.userService.putUser(this.user, body).then(() => {
        this.userService.putUserMeta(this.user.uid, body).then(() => {
          this.getUser();
          this._snackBar.open('Informations modifiées', null, {
            duration: 3000,
          });
          this.loading = false;
          this.showForm = false;
        }).catch(() => {
          this.loading = false;
          this._snackBar.open('Une erreur est survenue', null, {
            duration: 3000,
          });
        });
      }).catch(() => {
        this.loading = false;
        this._snackBar.open('Une erreur est survenue', null, {
          duration: 3000,
        });
      });
    }).catch(() => {
      this.loading = false;
      this._snackBar.open('Votre dernière connexion est trop ancienne pour modifier votre email, merci de vous re-connecter.', null, {
        duration: 5000,
      });
    });
  }

  delete() {
    var r = confirm("Êtes vous sur de vouloir supprimer votre compte ?");

    if (true === r) {
      this.adminUserService
        .adminDeleteUser({uid: this.user.uid})
        .subscribe(() => {
          this.allBusiness.forEach(business => {
            if (business.origin === 'Particulier') {
              this.collectionsService.putDocumentCollectionWithUid('Business', business.uid , {depositorUid: 'oZCEcwep87VZZCbdJ63urtlyhWy1'}).then(() => {
              }).catch(() => {
                this._snackBar.open('Une erreur est survenue', null, {
                  duration: 3000,
                });
              })
            } else {
              this.collectionsService.putDocumentCollectionWithUid('Business', business.uid , {depositorUid: this.userData.companyId}).then(() => {
              }).catch(() => {
                this._snackBar.open('Une erreur est survenue', null, {
                  duration: 3000,
                });
              })
            }
          });
          this.router.navigate(['/']);
        }, error => {
          this.router.navigate(['/']);
        })
    }
  }

}
