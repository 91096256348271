import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charter-protection',
  templateUrl: './charter-protection.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './charter-protection.component.scss']
})
export class CharterProtectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
