import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'immosansfrais';
  public hideCoockiePanel = false;

  loadGoogleAnalytics(): void {
      let gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=G-Q4L0DM0H18`);

      let gaScript2 = document.createElement('script');
      gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-Q4L0DM0H18\');`;

      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
  }

  constructor(
    private storage: LocalStorageService,
  ) {
    if (this.storage.get('hide_coockie_panel')) {
      this.hideCoockiePanel = true;
    }
    if (this.storage.get('coockie_accepted')) {
      this.loadGoogleAnalytics();
    }
  }

  ngOnInit(): void {
    AOS.init();
  }

  onCoockieAccepted() {
    this.storage.set('coockie_accepted', true);
    this.storage.set('hide_coockie_panel', true);
    this.hideCoockiePanel = true;
    this.loadGoogleAnalytics();
  }

  onCoockieRefused() {
    this.storage.set('coockie_accepted', false);
    this.storage.set('hide_coockie_panel', true);
    this.hideCoockiePanel = true;
  }
}
