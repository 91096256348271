import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

constructor(private http: HttpClient) { }

  adminCreateUser(body) {
    return this.http.post(`https://us-central1-immosansfrais.cloudfunctions.net/widgets/create-user`, body);
  }

  adminUpdateUser(body) {
    return this.http.put(`https://us-central1-immosansfrais.cloudfunctions.net/widgets/update-user`, body);
  }

  adminDeleteUser(body) {
    return this.http.post(`https://us-central1-immosansfrais.cloudfunctions.net/widgets/delete-user`, body);
  }

}
