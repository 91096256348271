import { Component, Input, OnInit } from "@angular/core";
import { QuerySnapshot } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map } from "rxjs/operators";
import { User } from "src/app/shared/interfaces/AuthUser";
import { Business } from "src/app/shared/interfaces/Business";
import { CollectionsService } from "src/app/shared/services";

@Component({
  selector: "app-right-sidebar-client",
  templateUrl: "./right-sidebar-client.component.html",
  styleUrls: [
    "../../../shared/assets/styles/styles.scss",
    "./right-sidebar-client.component.scss",
  ],
})
export class RightSidebarClientComponent implements OnInit {
  @Input() user: any;
  @Input() userData: User;

  allClosedBusiness: Business[] = [];
  allOngoingBusiness: Business[] = [];

  constructor(
    private collectionsService: CollectionsService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getAllBusiness();
    console.log(this.userData);
  }

  getAllBusiness() {
    this.collectionsService
      .getCollectionByKeyValue("Business", "depositorUid", this.user.uid)
      .pipe(
        map((business: QuerySnapshot<Business>) =>
          business.docs.map((d) => d.data())
        )
      )
      .subscribe(
        (business) => {
          this.allClosedBusiness = business.filter(
            (value) => value.status === "CLOSE"
          );
          this.allOngoingBusiness = business.filter(
            (value) => value.status === "ONGOING"
          );
        },
        (err) => {
          this._snackBar.open("Une erreur est survenue", null, {
            duration: 3000,
          });
        }
      );
  }
}
