import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules';
import { components } from './components';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { pages } from './pages';

const modules = [
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  RouterModule,
  MaterialModule,
  MomentModule
]

registerLocaleData(localeFr);

@NgModule({
  declarations: [...components, ...pages],
  imports: [
    CommonModule,
    ...modules,
  ],
  exports: [CommonModule, ...modules, ...components, ...pages],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
})
export class SharedModule { }
