import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services';
import { UserService } from 'src/app/shared/services';

@Injectable()
export class CompanyGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.company$.pipe(
      take(1),
      map((company) => !!(company)),
      tap((hasCompany) => {
          if (!hasCompany) {
            this._snackBar.open('Vous ne pouvez pas acceder à ce compte ou l\'entreprise n\'existe plus', null, {
              duration: 5000,
            });
            this.userService.resetUser();
          }
      }),
  );
  }
}
