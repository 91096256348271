import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
  @Output() coockieAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() coockieRefused: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
  }

  coockieAccepte() {
    this.coockieAccepted.emit(true);
  }

  coockieRefuse() {
    this.coockieRefused.emit(true);
  }

}
