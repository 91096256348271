import { Injectable } from '@angular/core';
import { AuthUser, User } from 'src/app/shared/interfaces/AuthUser';
import { combineLatest, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { uniq } from 'lodash';
import { AuthService } from 'src/app/auth/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollectionsService } from './collections.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  collection;
  usersCollection;
  private cache$: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(1);
  private companyCache$: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(1);
  public userId$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public userLoggedIn$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public userImage$: ReplaySubject<string> = new ReplaySubject<string>(1);

  public authUser$: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(1);

  public authUser: AuthUser;

  private isCached = false;
  get user$(): Observable<AuthUser>{
      return this.cache$;
  }
  get company$(): Observable<AuthUser>{
      return this.companyCache$;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
  ) {

    this.collection = 'Users';

    this.afAuth.onAuthStateChanged((user) => {
      this.userLoggedIn$.next(!!user);
      if (user) {
        this.usersCollection = this.afStore.collection<any>(this.collection);
        this.authUser = user;

        this.user$.subscribe((user) => {
          if (user.data.companyId) {
            this.collectionsService.getCollectionByDoc('Companies', user.data.companyId)
            .pipe(
              map(doc => doc.data()),
              take(1)
            ).subscribe ((data: any) => {
              this.companyCache$.next(data);
              this.authUser.data.company = data;
            })
          }
        });
        if (!this.isCached) {
          this.refreshUser().subscribe();
          this.isCached = true;
        }
      }
    });
  }

  resetUser(): void {
    this.cache$.next(null);
    this.userId$.next(null);
    this.userLoggedIn$.next(null);
    this.companyCache$.next(null);
    this.isCached = false;

    this.authService.signOut();
    window.location.reload();
  }

  refreshUser(): Observable<AuthUser>{
    this.isCached = true;

    return this.afStore.collection<AuthUser>('Users').doc(this.authUser.uid).get().pipe(
      tap((doc) => {
        if (doc.exists) {
          const userData: AuthUser = Object.assign(this.authUser, {data: doc.data()});
          this.cache$.next(userData);
          this.userId$.next(userData.uid);
        } else {
          this.cache$.next(this.authUser);
          this.userId$.next(this.authUser.uid);
          this.isCached = false;
          return throwError(of('NO DATA'));
        }
      }),
      catchError((err) => {
          this.isCached = false;
          return throwError(of(err));
      }),
    ) as Observable<AuthUser>;
  }

  putUserMeta(uid: string, body: any): Promise<any> {
    return this.usersCollection.doc(uid).set(body, { merge: true });
  }

  putUser(user: any, body: any): Promise<any> {
    return user.updateProfile(body);
  }

  putUserEmail(user: any, email: string): Promise<any> {
    return user.updateEmail(email);
  }
}
