import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { User } from "src/app/shared/interfaces/AuthUser";
import {
  CollectionsService,
  EmailTemplatesService,
  UserService,
} from "src/app/shared/services";

import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-contact-page",
  templateUrl: "./contact-page.component.html",
  styleUrls: [
    "../../../shared/assets/styles/styles.scss",
    "./contact-page.component.scss",
  ],
})
export class ContactPageComponent {
  form: FormGroup;
  loading = false;
  user?: any;
  userData?: User;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private emailTemplatesService: EmailTemplatesService,
    private collectionsService: CollectionsService
  ) {
    this.form = this.formBuilder.group({
      firstName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      lastName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      message: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });

    this.getUser();
  }

  getUser() {
    this.userService.user$.subscribe(
      (user) => {
        this.user = user;
        this.userData = user.data;
        if (this.userData) {
          this.form.patchValue(this.userData);
        }
      },
      () => {
        this._snackBar.open("Une erreur est survenue", null, {
          duration: 3000,
        });
      }
    );
  }

  submit() {
    if (this.form.invalid) {
      this._snackBar.open("Merci de vérifier le formulaire", null, {
        duration: 3000,
      });
      return;
    }

    // const emailsBody = [
    //   {
    //     to: this.form.value.email,
    //     subject: "Message envoyé à Immosansfrais.",
    //     content: this.emailTemplatesService.contactFormUser(),
    //   },
    //   {
    //     to: "contact@saintclair-patrimoine.fr",
    //     subject: "Vous avez un nouveau message !",
    //     content: this.emailTemplatesService.contactForm(this.form.value),
    //   }
    // ];

    // emailsBody.forEach(emailBody => {
    //   this.collectionsService.postDocumentCollection('Emails', emailBody).then(() => {
    //     this.loading = false;

    //     this.form.reset();

    //     for(var name in this.form.controls) {
    //       this.form.controls[name].setErrors(null);
    //     }

    //     this._snackBar.open('Email envoyé', null, {
    //       duration: 3000,
    //     });
    //   }).catch(() => {
    //     this.loading = false;

    //     this._snackBar.open('Une erreur est survenue', null, {
    //       duration: 3000,
    //     });
    //   })
    // });
  }
}
