import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedInSubj$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    public afDB: AngularFireDatabase,
  ) {}

  createUserWithEmailAndPassword(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }

  signInWithEmailAndPassword(credentials) {
    return this.afAuth.setPersistence('session').then(_ => {
      return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
    });
  }

  fetchSignInMethodsForEmail(email) {
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  signOut() {
    return this.afAuth.signOut().then(() => {
      this.router.navigate(['/auth/login']);
    })
  }

  forgotPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }
}
