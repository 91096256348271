import { Injectable } from "@angular/core";
import { Business } from "../interfaces/Business";

@Injectable({
  providedIn: "root",
})
export class EmailTemplatesService {
  constructor() {}

  // respoSignupForRespo() {
  //   return `<!DOCTYPE html>
  //   <html lang="fr">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //       <title>Vous êtes inscrit sur Immosansfrais</title>
  //   </head>
  //   <body>
  //       <div style="margin: 20px auto; width: 600px; text-align: center; font-family: Arial, Helvetica, sans-serif;">
  //           <div style="margin: 20px auto 40px auto;">
  //               <img style="width: 200px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Flogo-company.png?alt=media&token=b66ce3fe-4734-4be9-985d-95027a9ff818" alt="logo">
  //           </div>
  //           <div style="background-color: #F6F6F6; padding: 20px; border-radius: 20px;">
  //               <img style="margin: 0 auto; width: 400px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Fvisuel-mail-1.svg?alt=media&token=f92d28a3-881a-43f4-8aa8-ea060099f6bc">
  //               <p style="color: #143E84; font-weight: bold; font-size: 22px; margin-bottom: 0px;">Félicitations,</p>
  //               <p style="color: #143E84; margin-top: 10px;">votre compte <b><< NOM/PRÉNOM RESPONSABLE >></b> <br>
  //                 <b><< NOM ENTREPRISE >></b> a été créé sur Immosansfrais !</p>
  //           </div>
  //           <div style="margin: 40px auto">
  //               <p style="color: #143E84; font-weight: bold; font-size: 20px;">Vous pouvez désormais ajouter des collaborateurs <br>
  //                 afin qu’ils déposent leurs premières affaires.</p>
  //               <a href="https://business.saintclair-patrimoine.fr/" style="background: #BAA57C; color: #FFFFFF; font-weight: bold; text-decoration: none; font-size: 22px; padding: 10px 20px; display: inline-block; border-radius: 12px;">Se connecter</a>
  //               <p style="color: #585857; margin: 30px auto;">Pour rappel, chaque affaire « professionnelle » peut rapporter <b>1500€</b> à votre<br>
  //               entreprise si la vente s’effectue et <b>500€</b> au collaborateur.</p>
  //               <p style="color: #585857; margin-top: 0px;">Et chaque affaire « personnelle » peut rapporter <b>500€</b> à votre entreprise <br>si la vente s’effectue et <b>1500€</b> au collaborateur.</p>
  //               <p style="color: #585857;">À bientôt,</p>
  //               <p style="color: #585857; font-weight: bold;">L’équipe Immosansfrais !</p>
  //           </div>
  //       </div>
  //   </body>
  //   </html>`;
  // }

  colabSignupForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Votre accès à Saint-Clair est prêt !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Félicitations !
          </p>
          <p style="font-size: 16px; color: #FFF; margin-top: 10px">
            Vous êtes officiellement embassadeur Saint-Clair !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Vous pouvez désormais ajouter des collaborateurs <span style="color: #B9A784;">afin qu'ils déposent leurs premières affaires.</span>
            </p>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                  background-color: #002855;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 22px;
                  padding: 10px 20px;
                  display: inline-block;
                  border-radius: 3px;
                  font-family: Josefin Sans, Helvetica, sans-serif;
                  margin: 15px 0 20px 0;
                ">Se connecter</a>
          </div>
    
            <p style="
            color: #002855;
            font-weight: bold;
            margin-bottom: 0px;
            font-size: 20px;
            font-family: Josefin Sans, Helvetica, sans-serif;
          ">
              Pour rappel
            </p>
            <p style="color: #002855; margin-top: 15px; font-size: 13px;">
                Chaque affaire « professionnelle » peut vous rapporter <b style="color: #002855; font-size: 13px;">500€</b> si la vente s'effectue.
            </br>
                Chaque affaire « personnelle » peut vous rapporter <b style="color: #002855; font-size: 13px;">1500€</b> si la vente s'effectue.
            </p>
            <p style="color: #002855; font-size: 13px;">À bientôt,</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  colabSignupForAdmin(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Un nouveau collaborateur a été enregistré</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Un nouveau collaborateur a été enregistré !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Bonjour, Un nouveau collaborateur a été ajouté par <span style="color: #B9A784;">${data.respo.lastName} de ${data.respo.company.name} le ${data.date}</span>
            </p>
            <div style="margin: 40px auto; font-size: 13px;">
                <b>Les informations de ce nouvel ambassadeur : </b><br /><br />
                NOM: ${data.colab.firstName}<br />
                PRENOM: ${data.colab.lastName}<br />
                EMAIL: ${data.colab.email}<br />
                TELEPHONE: ${data.colab.phoneNumber}
          </div>
    
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
          background-color: #002855;
          color: #ffffff;
          text-decoration: none;
          font-size: 18px;
          padding: 10px 20px;
          display: inline-block;
          border-radius: 3px;
          font-family: Josefin Sans, Helvetica, sans-serif;
          margin: 15px 0 20px 0;
        ">Consulter sa fiche</a>
    </div>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  newBusinessForColab(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Votre affaire est déposée</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 20px 0 20px 0">
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Votre affaire est déposée !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Super, votre affaire à bien été déposée !
            </p>
    
    
            <div
            style="text-align: left; margin: auto 40px; font-size: 13px; color: #002855; border: solid 1px #002855; border-left: 0; padding: 30px 0;">
            <p>
              <span style="
                    color: #B9A784;
                    font-family: Josefin Sans;
                    padding: 9.5px;
                    margin-right: 5px;
                    font-size: 32px;
                  ">1</span>
              Nos équipes s'occupent de votre affaire !
            </p>
            <p style="
            margin-top: 30px;">
              <span style="
                    color: #B9A784;
                    font-family: Josefin Sans;
                    padding: 5px;
                    border-radius: 5px;
                    margin-right: 5px;
                    font-size: 32px;
                  ">2</span>
              Vous suivez l’avancée de l’affaire directement via votre espace
              client.
            </p>
            <div style="text-align: center; margin: 30px auto 20px auto">
              <a href="https://business.saintclair-patrimoine.fr/" style="
                background-color: #002855;
                color: #ffffff;
                text-decoration: none;
                font-size: 22px;
                padding: 10px 20px;
                display: inline-block;
                border-radius: 3px;
                font-family: Josefin Sans;
                margin: 30px 0 30px 0;
              ">Espace Collaborateur</a>
            </div>
            <div>
              <span style="
                    color: #B9A784;
                    font-family: Josefin Sans;
                    padding: 5px;
                    border-radius: 5px;
                    margin-right: 5px;
                    font-size: 32px;
                    display: inline-block;
                    width: 12px;
                    vertical-align: top;
                    margin-top: 8px;
                  ">3</span>
              <p style="display: inline-block; width: 420px; vertical-align: top">
                Vous voyez indiqué « Affaire conclue » ? Votre contact a acheté un bien grâce à Saint-Clair*.
                <b style="color: #B9A784;">Félicitations, cela vous rapporte 500€
                  (si affaire professionnelle) ou 1500€ (si affaire personnelle).</b>.
              </p>
            </div>
          </div>
          <p style="color: #002855; font-weight: bold; margin-top: 10px;">À bientôt</p>
          <small style="color: #002855; margin-top: 40px; font-size: 12px; font-style: italic;">
            * Il existe des délais légaux lors de l’achat d’un bien. Quelques mois
            sont à prévoir entre le dépôt de votre affaire sur notre plateforme et
            l’achat d’un bien qui mène vers votre commission.
          </small></br>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
      </div>
    </body>
    
    </html>
    `;
  }

  newBusinessForProspect(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Et si on parlait de votre projet ?</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 20px 0 20px 0">
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Et si on parlait de votre projet ?
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Bonjour, <span style="color: #B9A784;">${data.prospect.lastName} ${data.prospect.firstName} </span> a confié votre projet à nos experts Saint-Clair
            </p>    
          <p style="color: #002855; margin-top: 40px; font-size: 13px; font-family: Nunito Sans; text-wrap: balance;">
            Nous allons très vite prendre contact avec vous afin d’évoquer votre projet et de savoir comment nous pouvons vous aider à le réaliser.</br></br> Cordialement,
          </p></br>
          <p style="
          color: #002855;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
          ">L'équipe Saint-Clair</p>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
      </div>
    </body>
    
    </html>`;
  }

  newBusinessForRespo(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Une affaire a été déposée par un de vos collaborateurs !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 20px 0 20px 0">
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance;
              ">
            Une affaire a été déposée par ${data.colab.firstName} ${data.colab.lastName} !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif; text-wrap: balance;">
              Bonjour, une nouvelle affaire a été déposée sur Saint-Clair par <span style="color: #B9A784;">${data.colab.firstName} ${data.colab.lastName}</span>
            </p>
    
          <p style="
          color: #002855;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
          ">L'équipe Saint-Clair</p>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
      </div>
    </body>
    
    </html>`;
  }

  newBusinessForAdmin(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Nouvelle affaire déposée par  ${data.colab.firstName} ${data.colab.lastName} de ${data.company.name} !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 20px 0 20px 0">
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance;
              ">
            Une affaire a été déposée par ${data.colab.firstName} ${data.colab.lastName} !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Bonjour, une nouvelle affaire a été déposée par <span style="color: #B9A784;">${data.colab.firstName} ${data.colab.lastName}</span>
            </p>
            <div style="margin: 40px auto; font-size: 13px;">
                <b>Les informations de cette affaire : </b><br /><br />
                NOM: ${data.prospect.lastName}<br />
                PRENOM: ${data.prospect.firstName}<br />
                EMAIL: ${data.prospect.email}<br />
                TELEPHONE: ${data.prospect.phoneNumber}
          </div>
          <p style="color: #002855;
          text-align: center;
          font-family: Nunito Sans;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 190%;">
          Connectez-vous sur votre espace administrateur pour la consulter et changer son statut.
        </p>
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
          background-color: #002855;
          color: #ffffff;
          text-decoration: none;
          font-size: 18px;
          padding: 10px 20px;
          display: inline-block;
          border-radius: 3px;
          font-family: Josefin Sans, Helvetica, sans-serif;
          margin: 15px 0 20px 0;
        ">Connexion</a>
    </div>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
    
          <p style="
          color: #002855;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
          ">L'équipe Saint-Clair</p>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
      </div>
    </body>
    
    </html>`;
  }

  // apafValidatedForColab() {
  //   return `<!DOCTYPE html>
  //   <html lang="fr">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //       <title>Votre affaire est déposée !</title>
  //   </head>
  //   <body>
  //       <div style="margin: 20px auto; width: 600px; text-align: center; font-family: Arial, Helvetica, sans-serif;">
  //           <div style="margin: 20px auto 40px auto;">
  //               <img style="width: 200px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Flogo-company.png?alt=media&token=b66ce3fe-4734-4be9-985d-95027a9ff818" alt="logo">
  //           </div>
  //           <div style="background-color: #F6F6F6; padding: 20px; border-radius: 20px;">
  //               <img style="margin: 0 auto; width: 400px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Fvisuel-mail-1.svg?alt=media&token=f92d28a3-881a-43f4-8aa8-ea060099f6bc">
  //               <p style="color: #143E84; font-size: 22px;"><b>Félicitations,</b> <br>

  //                 votre contrat <b>d’apporteur d’affaires</b> est signé !</p>
  //           </div>
  //           <div style="margin: 40px auto">
  //               <p style="color: #143E84; font-size: 20px;">
  //                 Déposez vos premières affaires et gagnez <b>500€</b> ou <b>1500€</b>
  //                 selon le statut du contact déposé (professionnel ou personnel)
  //               </p>
  //               <a href="https://business.saintclair-patrimoine.fr/" style="background: #BAA57C; color: #FFFFFF; font-weight: bold; text-decoration: none; font-size: 22px; padding: 10px 20px; display: inline-block; border-radius: 12px;">Déposer vos affaires</a>
  //               <p style="color: #585857;">À bientôt</p>
  //               <p style="color: #585857; font-weight: bold;">L’équipe Immosansfrais !</p>
  //           </div>
  //       </div>
  //   </body>
  //   </html>`;
  // }

  // businessSatusChangeForColab() {
  //   return `<!DOCTYPE html>
  //   <html lang="fr">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //       <title>Le statut de votre affaire a changé !</title>
  //   </head>
  //   <body>
  //     <div style="margin: 20px auto; width: 600px; text-align: center; font-family: Arial, Helvetica, sans-serif;">
  //         <div style="margin: 20px auto 40px auto;">
  //             <img style="width: 200px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Flogo-company.png?alt=media&token=b66ce3fe-4734-4be9-985d-95027a9ff818" alt="logo">
  //         </div>
  //         <div style="background-color: #F6F6F6; padding: 20px; border-radius: 20px;">
  //             <p style="color: #143E84; font-weight: bold; font-size: 22px;">Immosansfrais a changé le statut de votre affaire <br>
  //               concernant le contact <br><br>
  //               << NOM AFFAIRE >> <br><br>
  //               << PRÉNOM AFFAIRE >></p>
  //         </div>
  //         <div style="margin: 40px auto">
  //             <p style="color: #143E84; font-size: 20px;">
  //               Consultez l’état d’avancée de l
  //               ’affaire directement sur votre Espace
  //               <br>
  //               Collaborateur :
  //             </p>
  //             <a href="https://business.saintclair-patrimoine.fr/" style="background: #BAA57C; color: #FFFFFF; font-weight: bold; text-decoration: none; font-size: 22px; padding: 10px 20px; display: inline-block; border-radius: 12px;">Espace Collaborateur</a>
  //             <p style="color: #585857;">À très bientôt,</p>
  //             <p style="color: #585857; font-weight: bold;">L’équipe Immosansfrais !</p>
  //         </div>
  //     </div>
  //   </body>
  //   </html>`;
  // }

  // businessClosedForColab() {
  //   return `<!DOCTYPE html>
  //   <html lang="fr">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //       <title>Votre affaire est conclue !</title>
  //   </head>
  //   <body>
  //       <div style="margin: 20px auto; width: 600px; text-align: center; font-family: Arial, Helvetica, sans-serif;">
  //           <div style="margin: 20px auto 40px auto;">
  //               <img style="width: 200px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Flogo-company.png?alt=media&token=b66ce3fe-4734-4be9-985d-95027a9ff818" alt="logo">
  //           </div>
  //           <div style="background-color: #F6F6F6; padding: 20px; border-radius: 20px;">
  //               <img style="margin: 0 auto; width: 400px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Fvisuel-mail-8.svg?alt=media&token=f1755013-0683-40a4-ba7d-cc8b94a86d1a">
  //               <p style="color: #143E84; font-weight: bold; font-size: 22px; margin-bottom: 0px;">Votre affaire est conclue !</p>
  //           </div>
  //           <div style="margin: 40px auto">
  //               <p style="color: #143E84; font-size: 20px;">Grâce à votre affaire, vous remportez
  //                 <b>500€</b> (si affaire pro) / <b>1500€</b> (si affaire perso) !</p>
  //               <p style="color: #585857;">Rendez-vous sur votre Espace Collaborateur pour déposer de nouvelles affaires.</p>
  //               <a href="https://business.saintclair-patrimoine.fr/" style="background: #BAA57C; color: #FFFFFF; font-weight: bold; text-decoration: none; font-size: 22px; padding: 10px 20px; display: inline-block; border-radius: 12px;">Espace Collaborateur</a>

  //               <p style="color: #585857;">À bientôt,</p>
  //               <p style="color: #585857; font-weight: bold;">L’équipe Immosansfrais !</p>
  //           </div>
  //       </div>
  //   </body>
  //   </html>
  //   `;
  // }

  // businessClosedForRespo() {
  //   return `<!DOCTYPE html>
  //   <html lang="fr">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //       <title>Une affaire est conclue !</title>
  //   </head>
  //   <body>
  //       <div style="margin: 20px auto; width: 600px; text-align: center; font-family: Arial, Helvetica, sans-serif;">
  //           <div style="margin: 20px auto 40px auto;">
  //               <img style="width: 200px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Flogo-company.png?alt=media&token=b66ce3fe-4734-4be9-985d-95027a9ff818" alt="logo">
  //           </div>
  //           <div style="background-color: #F6F6F6; padding: 20px; border-radius: 20px;">
  //               <img style="margin: 0 auto; width: 400px;" src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2Fvisuel-mail-8.svg?alt=media&token=f1755013-0683-40a4-ba7d-cc8b94a86d1a">
  //               <p style="color: #143E84; font-weight: bold; font-size: 22px; margin-bottom: 0px;">Une affaire déposée par
  //                 <br><br>
  //                 << NOM PRÉNOM COLLAB >> est conclue !</p>
  //           </div>
  //           <div style="margin: 40px auto">
  //               <p style="color: #143E84; font-size: 20px;">Grâce à cette affaire, vous remportez
  //                 <b>1500€</b> (si affaire pro) / <b>500€</b> (si affaire perso) !</p>
  //               <p style="color: #585857;">À bientôt,</p>
  //               <p style="color: #585857; font-weight: bold;">L’équipe  Saint-Clair !</p>
  //           </div>
  //       </div>
  //   </body>
  //   </html>`;
  // }

  proToPartForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Changement de votre affaire effectué</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
                line-height: 150%;
              ">
            Le changement de votre affaire en
            "Particulier" a bien été enregistré !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Si l'affaire est conclue, <span style="color: #B9A784;">vous gagnerez désormais 1500€ !</span>
            </p>
            <small style="text-wrap: balance;">N'hésitez pas à consulter régulièrement votre compte Saint-Clair</br>
              afin de suivre l'avancée de votre affaire.</small>
          </div>
    
            <p style="color: #002855; font-size: 13px;">Cordialement.</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  partToProForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Changement de votre affaire effectué</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
                line-height: 150%;
              ">
            Le changement de votre affaire en
            "Professionnel" a bien été enregistré !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Si l'affaire est conclue, <span style="color: #B9A784;">vous gagnerez désormais 500€ !</span>
            </p>
            <small style="text-wrap: balance;">N'hésitez pas à consulter régulièrement votre compte Saint-Clair</br>
              afin de suivre l'avancée de votre affaire.</small>
          </div>
    
            <p style="color: #002855; font-size: 13px;">Cordialement.</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }
}
