import { ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthService } from '../auth/services';
import { User } from '../shared/interfaces/AuthUser';
import { UserService } from '../shared/services';

@Component({
  templateUrl: './client.component.html',
  styleUrls: ['../shared/assets/styles/styles.scss', './client.component.scss']
})
export class ClientComponent {
  switchAccountType = false;
  user?: any;
  userData?: User;

  constructor(
    public router: Router,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private storage: LocalStorageService,
    private authService: AuthService,
  ) {
    this.getUser();
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.cdr.markForCheck();
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  changeAccountType() {
    this.switchAccountType = true;
    this.storage.set('admin', true);
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }, 2000);
  }

  logout() {
    this.userService.resetUser();
  }
}
